import { defineStore } from "pinia";
import { DEFAULT_REG_INFO } from "@/constants";
export const useGetStartedStore = defineStore("getStarted", {
  state: () => ({
    step: "landing",
    modalBg: "shots",
    modalState: false,
    createForm: {
      username: null,
      password: null,
      first_name: null,
      last_name: null,
      gender: DEFAULT_REG_INFO.GENDER,
      location: DEFAULT_REG_INFO.COUNTRY,
      country_code: DEFAULT_REG_INFO.COUNTRY,
      phone: null,
      agreeToTerms: false,
    },
    ssoResponse: {
      token: null,
      tokenType: null,
      otpToken: null,
    },
  }),
  actions: {
    closeModal() {
      this.modalState = false;
      this.$reset();
    },
    openModal(step = "landing") {
      this.modalState = true;
      this.step = step;
    },
  },
});
